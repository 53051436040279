import React from 'react';
import Appstore from '../../img/appstore.png';
import Googleplay from '../../img/googleplay.png';

export default function BenefitFeaturesTwoColumn({
  iconOne,
  headerOne,
  paragraphOne,
  quote,
  quoteName,
  imageOne,
  iconTwo,
  headerTwo,
  paragraphTwo,
  imageTwo,
}) {
  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-yellow-400">
                  {iconOne}
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {headerOne}
                </h2>
                <p className="mt-4 text-lg text-gray-500">{paragraphOne}</p>
                <div className="mt-6">
                  <div className="flex mt-8 sm:max-w-lg">
                    <a href="https://apps.apple.com/us/app/mijn-homerun/id1514372640">
                      <img
                        className="pr-4 w-36"
                        id="appstore"
                        src={Appstore}
                        alt="Appstore badge"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.mijnhomerun.homerun&hl=en&gl=US">
                      <img
                        className="pl-4 w-40"
                        id="googleplay"
                        src={Googleplay}
                        alt="GooglePlay badge"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">&ldquo;{quote}&rdquo;</p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="text-base font-medium text-gray-700">{quoteName}</div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              {imageOne}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-yellow-400">
                  {iconTwo}
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {headerTwo}
                </h2>
                <p className="mt-4 text-lg text-gray-500">{paragraphTwo}</p>
                <div className="mt-6">
                  <div className="flex mt-8 sm:max-w-lg">
                    <a href="https://apps.apple.com/us/app/mijn-homerun/id1514372640">
                      <img
                        className="pr-4 w-36"
                        id="appstore"
                        src={Appstore}
                        alt="Appstore badge"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.mijnhomerun.homerun&hl=en&gl=US">
                      <img
                        className="pl-4 w-40"
                        id="googleplay"
                        src={Googleplay}
                        alt="GooglePlay badge"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <div className="-ml-48 rounded-xl shadow-xl ring-1 ring-black ring-opacity-5">
                {imageTwo}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
