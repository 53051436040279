import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  ChatAlt2Icon,
  ReceiptTaxIcon,
  PaperClipIcon,
  UserAddIcon,
  UserGroupIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import Layout from '../../components/layout';
import BenefitHero from '../../components/Benefit/BenefitHero';
import BenefitFeatures from '../../components/Benefit/BenefitFeatures';
import BenefitFeaturesImage from '../../components/Benefit/BenefitFeaturesImage';
import BenefitCta from '../../components/Benefit/BenefitCta';
import BenefitFeaturesTwoColumn from '../../components/Benefit/BenefitFeaturesTwoColumn';
import BookDemo from '../../components/BookDemo';
import { SEO } from '../../components/SEO/SEO';

const FeaturesList = [
  {
    name: 'Maak een (groeps)chat aan',
    description:
      'Maak een (groeps)chat aan, nodig projectleden uit en chat met alle projectleden zodat je belangrijke bestanden en informatie met een druk op de knop kujnt delen.',
    icon: ChatAlt2Icon,
  },
  {
    name: 'Maak materiaalbriefjes aan',
    description:
      'Geef je projectleden inzicht in de planning. Zo hoef je de planning niet meer te printen of te mailen. Wanneer jij een aanpassing doet is deze direct voor iedereen inzichtelijk. ',
    icon: ReceiptTaxIcon,
  },
  {
    name: 'Deel belangrijke bestanden',
    description:
      'Leg afspraken vast met je projectleden. Deel de informatie met klant, onderaannemer en medewerkers. Zo is iedereen atijd up-to-date.',
    icon: PaperClipIcon,
  },
  {
    name: 'Bepaal zelf wie de informatie te zien krijgt',
    description:
      'Wil je zelf kiezen wie welke informatie te zien krijgt? Homigo geeft je de mogelijkheid te bepalen in hoeverre iemand toegang heeft tot de informatie die je deelt binnen de app.',
    icon: UserAddIcon,
  },
];

const DeelPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Deel' }} />
    <BenefitHero
      heading={
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Deel informatie met</span>{' '}
          <span className="block text-yellow-400 xl:inline">alle projectleden</span>
        </h1>
      }
      subheading="
        Deel makkelijk en snel al je belangrijke informatie met projectleden. Of het nu om bouwtekeningen, foto’s of andere documenten gaat; met Homigo zijn alle projectleden altijd op de hoogte. "
    />

    <BenefitFeatures
      title="CHAT EN DEEL"
      heading="Iedereen op de hoogte"
      subheading="Zorg dat alle projectleden altijd op de hoogte zijn en bespaar jezelf een hoop telefoontjes, appjes en emails per dag."
      features={FeaturesList}
    />

    <BenefitFeaturesImage
      title="Slim en eenvoudig"
      heading="Sneller schakelen? Zo doe je dat. "
      subheading="Homigo helpt je informatie sneller te delen. Moet er nog een stopcontact geplaatst worden? Maak een foto en deel het direct in de chat.  Bespaar jezelf tientallen telefoontjes per dag door alles vast te leggen binnen één app."
      image={
        <StaticImage
          src="../../img/share_large.png"
          alt=""
          className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
        />
      }
    />

    <BenefitCta
      heading={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Meer omzet, minder faalkosten?</span>
          <span className="">Download</span>
          <span className="text-primary"> Homigo </span>
          <span className="">vandaag nog.</span>
        </h2>
      }
      subheading="Homigo is verkrijgbaar voor zowel IOS als Android en is ook te gebruiken als webversie op je computer."
    />
    <BenefitFeaturesTwoColumn
      iconOne={<UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />}
      headerOne="Iedereen altijd op de hoogte"
      paragraphOne="Moeten er sloten vervangen worden? Weet je medewerker dat de
                  deur aan de rechterkant i.p.v. de linkerkant gehangen moet
                  worden? Met de planningstool stroomlijn je de gehele klus van
                  A tot Z. Maak werkbonnen aan, dien materiaalbriefjes in en
                  zorg dat de aangewezen projectleden op de hoogte zijn!"
      quote="Doordat mijn jongens allemaal toegang hebben tot de bestanden die ik deel, zit ik dagelijks een stuk minder aan de telefoon vragen te beantwoorden."
      quoteName="Tim Janssens, Aaannemer"
      imageOne={<StaticImage src="../../img/share_iphone.png" alt="" className="" />}
      iconTwo={<QuestionMarkCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />}
      headerTwo="Minder vragen beantwoorden"
      paragraphTwo="Wanneer iedereen op de hoogte is van de planning, hoef jij
                  niet elke keer onnodige vragen te beantwoorden. Al je
                  projectleden blijven op de hoogte van eventuele wijzigingen.
                  Plan alles binnen één app en houd meer tijd over om te bouwen."
      imageTwo={
        <StaticImage src="../../img/share_desktop.png" alt="Customer profile user interface" />
      }
    />
    <BookDemo />
  </Layout>
);

export default DeelPage;
